/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 31, 2020 */


@font-face {
  font-family: 'Now';
  src: url('now-light-webfont.woff2') format('woff2'),
    url('now-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;

}

@font-face {
  font-family: 'Now';
  src: url('now-medium-webfont.woff2') format('woff2'),
    url('now-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;

}

@font-face {
  font-family: 'Now';
  src: url('now-regular-webfont.woff2') format('woff2'),
    url('now-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;

}

@font-face {
  font-family: 'Now';
  src: url('now-bold-webfont.woff2') format('woff2'),
    url('now-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;

}

@font-face {
  font-family: 'Anton';
  src: url('anton-regular-webfont.woff2') format('woff2'),
    url('anton-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Quentin';
  src: url('quentin-webfont.woff2') format('woff2'),
    url('quentin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@charset 'utf-8';

$fa-font-path: "../node_modules/@fortawesome/fontawesome-free/webfonts";

@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

//@import "./styles/settings.scss";
//@import "./styles/mixins.scss";
//@import "./styles/swiper/pagination.scss";
//@import "./styles/cookiebanner.scss";

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
  }
}

.embed--inline {
  margin-top: 0.5rem;
}

// Modifier class for 16:9 aspect ratio
.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

// Modifier class for 4:3 aspect ratio
.embed-responsive-4by3 {
  padding-bottom: 75%;
}

.fa-1_5x {
  font-size: 1.667em;
}

.wagtail-userbar-reset .wagtail-userbar__item .wagtail-action,
.wagtail-userbar-reset .wagtail-userbar__item a {
  color: #aaa;
  display: flex;
  text-decoration: none !important;
  transform: none !important;
  transition: none !important;
  margin: 0 !important;
  font-size: 14px !important;
  align-items: center;
}
